import { detect } from 'detect-browser'

/**
 * OutdatedBrowsers can also be used to add classes on the body for easier css fixes
 */
export default {
  name: 'outdated-browsers',

  ui: {
    closeBtn: '[js-outdated-browsers-close]',
  },

  events: [
    {
      target: 'closeBtn',
      on: 'click',
      handle () {
        this.el.classList.add('is-hidden')
      },
    },
  ],

  componentDidMount () {
    const browser = detect()
    const version = parseInt(browser.version)
    let outdated = false

    if (version) {
      switch (browser.name) {
        case 'chrome':
          outdated = version < 57
          break
        case 'edge':
          outdated = version < 39
          break
        case 'safari':
          outdated = version < 10
          break
        case 'firefox':
          outdated = version < 50
          break
        case 'opera':
          outdated = version < 50
          break
        case 'vivaldi':
          outdated = version < 1
          break
        case 'ie':
          outdated = version < 11
          break
      }
    }

    if (outdated) {
      this.el.classList.add('is-loaded')
      this.el.classList.remove('is-hidden')
    }
  },
}
