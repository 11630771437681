import Highway from '@dogstudio/highway'
import anime from 'animejs'

import emitter from '../utils/emitter'

class FadeTransition extends Highway.Transition {
  in ({ from, to, trigger, done }) {
    document.documentElement.style.cursor = ''

    anime({
      targets: '.transition-layer',
      opacity: [1, 0],
      duration: 350,
      // delay: 200,
      easing: 'easeInCubic',
      begin: () => {
        emitter.trigger('TRANSITION_IN')
      },
      complete: () => {
        done()
      },
    })
  }

  out ({ from, trigger, done }) {
    document.documentElement.style.cursor = 'wait'

    anime({
      targets: '.transition-layer',
      opacity: [0, 1],
      duration: 350,
      easing: 'easeOutCubic',
      begin: () => {
        emitter.trigger('TRANSITION_OUT')
      },
      complete: () => {
        from.remove()
        window.scrollTo(0, 0)
        done()
      },
    })
  }
}

export default {
  name: 'highway',

  componentDidMount () {
    this.highway = new Highway.Core({
      transitions: {
        default: FadeTransition,
      },
    })
  },
}
