export default {
  name: 'creation-grid',

  ui: {
    'items[]': '[js-creation-grid-item]',
  },

  events: [
    {
      target: 'items',
      on: 'mouseenter',
      handle () {
        this.el.classList.add('is-hovered')
      },
    },
    {
      target: 'items',
      on: 'mouseleave',
      handle () {
        this.el.classList.remove('is-hovered')
      },
    },
  ],
}
