/* global L */

/**
 * Require the Leaflet lib, imported in the dom.
 */
export default {
  name: 'contact-map',

  componentDidMount () {
    // Setup map
    const coords = [46.5867, 1.9852]

    this.map = L.map(this.el, {
      center: coords,
      zoom: 6,
    })

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      maxZoom: 18,
      // id: 'mapbox.streets',
      accessToken:
        'pk.eyJ1IjoiYWFoZHJpZW4iLCJhIjoiY2sweTFxZWxwMDlzdjNubXVuOTczaWp0MSJ9.TPTzJBRalwYgjRGeymvtSw',
    }).addTo(this.map)

    const marker = L.marker(coords, {
      alt: 'Marqueur de position Décocath',
    }).addTo(this.map)
    marker.bindPopup(`<b>Décocath</b><br>${this.props.address}`)
  },

  componentWillUnmount () {
    this.map.remove()
  },
}
