import { tns } from 'tiny-slider/src/tiny-slider'

export default {
  name: 'slider',

  ui: {
    target: '[js-slider-target]',
    btnPrev: '[js-slider-btn-prev]',
    btnNext: '[js-slider-btn-next]',
  },

  componentDidMount () {
    this.slider = tns({
      container: this.ui.target,
      prevButton: this.ui.btnPrev,
      nextButton: this.ui.btnNext,
      items: 1,
      autoWidth: true,
      center: true,
      gutter: 20,
      speed: 500,
      // autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      autoplayButtonOutput: false,
    })

    const infos = this.slider.getInfo()
    infos.slideItems[infos.index].classList.add('is-active')

    this.slider.events.on('indexChanged', this.handleChange)

    setTimeout(() => {
      this.el.classList.add('is-visible')
    }, 500)
  },

  handleChange (e) {
    e.slideItems[e.indexCached].classList.remove('is-active')
    e.slideItems[e.index].classList.add('is-active')
  },
}
