import 'intersection-observer'
// import 'promise-polyfill/src/polyfill'
import { createComponent } from '@bigyouth/base'

import './polyfills'
import './assets'

import './style/styles.scss'

import './components'
import * as componentsBase from './components-base'

Object.values(componentsBase).forEach(createComponent)
