// import scrollManager from '../utils/scrollManager'
import emitter from '../utils/emitter'
import onScroll from '../utils/onScroll'

export default {
  name: 'header',

  MIN_SCROLL: window.innerHeight * 0.05,
  isMobile: window.innerWidth < 768,
  isPanelOpen: false,
  isScrolled: window.pageYOffset >= window.innerHeight * 0.05,
  currentBaseRoute: window.location.pathname.split('/')[1],

  ui: {
    'links[]': '[js-header-nav-link]',
    'mobileLinks[]': '[js-header-mobile-nav-link]',
    navLogo: '[js-header-nav-logo]',
    btnBurger: '[js-header-btn-burger]',
    panel: '[js-header-panel]',
  },

  events: [
    {
      target: 'navLogo',
      on: 'click',
      handle () {
        if (this.isPanelOpen) {
          this.ui.btnBurger.classList.remove('is-open')
          this.ui.panel.classList.remove('is-open')
        }
      },
    },
    {
      target: 'mobileLinks',
      on: 'click',
      handle () {
        this.ui.btnBurger.classList.remove('is-open')
        this.ui.panel.classList.remove('is-open')
      },
    },
    {
      target: 'btnBurger',
      on: 'click',
      handle () {
        const domContent = document.querySelector('.content')

        this.ui.btnBurger.classList.toggle('is-open')
        this.ui.panel.classList.toggle('is-open')
        this.isPanelOpen = !this.isPanelOpen

        if (this.isPanelOpen) {
          // this.scrollOffTimeout = setTimeout(() => {
          //   scrollManager.stop()
          // }, 500)

          this.ui.panel.removeAttribute('js-sr-inactive')
          domContent.setAttribute('js-sr-inactive', '')
        } else {
          // scrollManager.restart()

          this.ui.panel.setAttribute('js-sr-inactive', '')
          domContent.removeAttribute('js-sr-inactive')
        }
      },
    },
  ],

  componentDidMount () {
    if (this.isScrolled) {
      this.el.classList.add('is-scrolled')
    }

    this.offScroll = onScroll(this.handleScroll)

    emitter.on('TRANSITION_OUT', () => {
      const nextBaseRoute = window.location.pathname.split('/')[1]

      if (nextBaseRoute !== this.currentBaseRoute) {
        this.currentBaseRoute = nextBaseRoute

        this.ui.links.forEach(link => {
          link.classList.remove('is-active')
        })

        this.ui.mobileLinks.forEach(link => {
          link.classList.remove('is-active')
        })
      }
    })

    emitter.on('TRANSITION_IN', () => {
      const nextBaseRoute = window.location.pathname.split('/')[1]

      const nextLink = this.ui.links.find(
        link => link.getAttribute('js-header-nav-link') === nextBaseRoute
      )
      const nextMobileLink = this.ui.mobileLinks.find(
        link => link.getAttribute('js-header-mobile-nav-link') === nextBaseRoute
      )

      nextLink.classList.add('is-active')
      nextMobileLink.classList.add('is-active')
    })
  },

  componentWillUnmount () {
    this.offScroll()
  },

  handleScroll (e) {
    if (this.isScrolled) {
      if (e.y < this.MIN_SCROLL) {
        this.isScrolled = false
        this.el.classList.remove('is-scrolled')
      }
    } else if (e.y >= this.MIN_SCROLL) {
      this.isScrolled = true
      this.el.classList.add('is-scrolled')
    }
  },
}
