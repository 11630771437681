/*eslint-disable */

// Object ES7
if (!Object.values) Object.values = o => Object.keys(o).map(k => o[k])
if (!Object.entries) Object.entries = o => Object.keys(o).map(k => [k, o[k]])

// Array ES7
if (!Array.from) Array.from = o => [].slice.call(o)

// Element ES7
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector
}

// Nodelist ES6
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this)
    }
  }
}

// Strings ES7
if (!String.prototype.startsWith) {
  ;(function() {
    'use strict' // needed to support `apply`/`call` with `undefined`/`null`
    var defineProperty = (function() {
      // IE 8 only supports `Object.defineProperty` on DOM elements
      try {
        var object = {}
        var $defineProperty = Object.defineProperty
        var result = $defineProperty(object, object, object) && $defineProperty
      } catch (error) {}
      return result
    })()
    var toString = {}.toString
    var startsWith = function(search) {
      if (this == null) {
        throw TypeError()
      }
      var string = String(this)
      if (search && toString.call(search) == '[object RegExp]') {
        throw TypeError()
      }
      var stringLength = string.length
      var searchString = String(search)
      var searchLength = searchString.length
      var position = arguments.length > 1 ? arguments[1] : undefined
      // `ToInteger`
      var pos = position ? Number(position) : 0
      if (pos != pos) {
        // better `isNaN`
        pos = 0
      }
      var start = Math.min(Math.max(pos, 0), stringLength)
      // Avoid the `indexOf` call if no match is possible
      if (searchLength + start > stringLength) {
        return false
      }
      var index = -1
      while (++index < searchLength) {
        if (
          string.charCodeAt(start + index) != searchString.charCodeAt(index)
        ) {
          return false
        }
      }
      return true
    }

    if (defineProperty) {
      defineProperty(String.prototype, 'startsWith', {
        value: startsWith,
        configurable: true,
        writable: true,
      })
    } else {
      String.prototype.startsWith = startsWith
    }
  })()
}
